<template>
    <v-text-field
        v-bind="$attrs"
        :class="['my-auto', { 'success': isUploadSuccess }]"
        :loading="isLoading"
        :hide-details="true"
        outlined
        dense
        @input="triggerUpdateTrackingNo($event)"        
    ></v-text-field>
</template>
<script>
import { asyncUpdate } from '@/helpers/asyncAxios'
export default {
  props: {
    id: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isUploadSuccess: false,
      updateDelayTimer: null,
    }
  },
  methods: {
    triggerUpdateTrackingNo(trackingNo) {
      if (!!this.updateDelayTimer) clearTimeout(this.updateDelayTimer)

      this.updateDelayTimer = setTimeout(this.updateTrackingNo, 250, this.id, trackingNo)
    },
    async updateTrackingNo(order_id, trackingNo) {
      this.isLoading = true

      try {
        await asyncUpdate('/saleorder/' + order_id + '/tracking', { trackingNo: trackingNo })
        this.isUploadSuccess = true
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.isLoading = false
    },
  },
}
</script>